"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm$user$role, _vm$user$role2, _vm$user$role3, _vm$user$role4, _vm$user$role5, _vm$user$role6, _vm$user$role7;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    key: true,
    attrs: {
      title: _vm.status == "create" ? "Thêm mới người dùng" : "Cập nhật người dùng",
      visible: _vm.visible,
      width: _vm.activeTab === "user-info" || _vm.status == "create" ? "700px" : "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleResetActiveTab
    }
  }, [_vm.status === "update" && _vm.isDaiLy ? _c("el-tabs", {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin người dùng",
      name: "user-info"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules,
      disabled: ((_vm$user$role = _vm.user.role) === null || _vm$user$role === void 0 ? void 0 : _vm$user$role.type) == _vm.SyncPortalRole.DaiLy2
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SyncId",
      prop: "syncId"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.syncId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "syncId", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.syncId"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Username",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "show-word-limit": "",
      maxlength: 40,
      size: "medium"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SDT",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      value: _vm.form.phone
    },
    on: {
      input: _vm.handleChangePhone
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại người dùng",
      prop: "roleId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      disabled: ((_vm$user$role2 = _vm.user.role) === null || _vm$user$role2 === void 0 ? void 0 : _vm$user$role2.type) == _vm.SyncPortalRole.DaiLy
    },
    on: {
      change: _vm.handleRoleChange
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }, _vm._l(_vm.roles, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _vm.isDaiLy || _vm.isDaiLy2 ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã đại lý",
      prop: "refCode",
      rules: [{
        required: true,
        message: "Mã đại lý is required",
        trigger: "blur"
      }, {
        validator: _vm.validateRefCode,
        trigger: "blur"
      }],
      "show-message": ""
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      maxlength: "10"
    },
    model: {
      value: _vm.form.refCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "refCode", $$v);
      },
      expression: "form.refCode"
    }
  })], 1)], 1) : _vm._e(), _vm.isDaiLy || _vm.isDaiLy2 ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "% Hoa hồng",
      prop: "refCode",
      rules: [{
        required: true,
        message: "% hoa hồng bắt buộc",
        trigger: "blur"
      }],
      "show-message": ""
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      max: 100
    },
    model: {
      value: _vm.form.commissionPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionPercent", $$v);
      },
      expression: "form.commissionPercent"
    }
  })], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Facebook",
      prop: "facebook"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.facebook,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "facebook", $$v);
      },
      expression: "form.facebook"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số zalo",
      prop: "zalo"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.zalo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "zalo", $$v);
      },
      expression: "form.zalo"
    }
  })], 1)], 1), !_vm.isDaiLy ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thuộc đại lý",
      prop: "refStaffId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      disabled: ((_vm$user$role3 = _vm.user.role) === null || _vm$user$role3 === void 0 ? void 0 : _vm$user$role3.type) == _vm.SyncPortalRole.DaiLy
    },
    model: {
      value: _vm.form.refStaffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "refStaffId", $$v);
      },
      expression: "form.refStaffId"
    }
  }, _vm._l(_vm.agents, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giới tính",
      prop: "gender"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, _vm._l(_vm.GenderTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Avatar",
      prop: "avatar"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 100,
      pathImage: _vm.form.avatar,
      uploadUrl: "/v1/admin/staff/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.form, "avatar", path);
      }
    }
  })], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Danh sách đại lý con",
      name: "agency-list"
    }
  }, [_c("agency-child-list", {
    attrs: {
      parentAgentId: _vm.form.id
    }
  })], 1)], 1) : _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules,
      disabled: ((_vm$user$role4 = _vm.user.role) === null || _vm$user$role4 === void 0 ? void 0 : _vm$user$role4.type) == _vm.SyncPortalRole.DaiLy2
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SyncId",
      prop: "syncId"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.syncId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "syncId", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.syncId"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Username",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _vm.status == "create" ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mật khẩu",
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      type: _vm.showPass ? "text" : "password"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c("div", {
    staticClass: "show-pwd"
  }, [!_vm.showPass ? _c("font-awesome-icon", {
    attrs: {
      icon: "eye"
    },
    on: {
      click: function click($event) {
        _vm.showPass = true;
      }
    }
  }) : _c("font-awesome-icon", {
    attrs: {
      icon: "eye-slash"
    },
    on: {
      click: function click($event) {
        _vm.showPass = false;
      }
    }
  })], 1)], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "show-word-limit": "",
      maxlength: 40,
      size: "medium"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "SDT",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      value: _vm.form.phone
    },
    on: {
      input: _vm.handleChangePhone
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại người dùng",
      prop: "roleId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      disabled: ((_vm$user$role5 = _vm.user.role) === null || _vm$user$role5 === void 0 ? void 0 : _vm$user$role5.type) == _vm.SyncPortalRole.DaiLy
    },
    on: {
      change: _vm.handleRoleChange
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }, _vm._l(_vm.roles, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _vm.isDaiLy || _vm.isDaiLy2 ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã đại lý",
      prop: "refCode",
      rules: [{
        required: true,
        message: "Mã đại lý is required",
        trigger: "blur"
      }, {
        validator: _vm.validateRefCode,
        trigger: "blur"
      }],
      "show-message": ""
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium",
      maxlength: "10"
    },
    model: {
      value: _vm.form.refCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "refCode", $$v);
      },
      expression: "form.refCode"
    }
  })], 1)], 1) : _vm._e(), _vm.isDaiLy || _vm.isDaiLy2 ? _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "% Hoa hồng",
      prop: "commissionPercent",
      rules: [{
        required: true,
        message: "% hoa hồng bắt buộc",
        trigger: "blur"
      }],
      "show-message": ""
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      max: 100
    },
    model: {
      value: _vm.form.commissionPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionPercent", $$v);
      },
      expression: "form.commissionPercent"
    }
  })], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Facebook",
      prop: "facebook"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.facebook,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "facebook", $$v);
      },
      expression: "form.facebook"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số zalo",
      prop: "zalo"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.zalo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "zalo", $$v);
      },
      expression: "form.zalo"
    }
  })], 1)], 1), !_vm.isDaiLy ? _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thuộc đại lý",
      prop: "refStaffId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      disabled: ((_vm$user$role6 = _vm.user.role) === null || _vm$user$role6 === void 0 ? void 0 : _vm$user$role6.type) == _vm.SyncPortalRole.DaiLy
    },
    model: {
      value: _vm.form.refStaffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "refStaffId", $$v);
      },
      expression: "form.refStaffId"
    }
  }, _vm._l(_vm.agents, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giới tính",
      prop: "gender"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, _vm._l(_vm.GenderTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Avatar",
      prop: "avatar"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 100,
      pathImage: _vm.form.avatar,
      uploadUrl: "/v1/admin/staff/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.form, "avatar", path);
      }
    }
  })], 1)], 1), ((_vm$user$role7 = _vm.user.role) === null || _vm$user$role7 === void 0 ? void 0 : _vm$user$role7.type) !== _vm.SyncPortalRole.DaiLy2 && _vm.activeTab !== "agency-list" ? _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.status != "create" ? _c("div", [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "danger",
      size: "default",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium",
      type: "warning",
      loading: _vm.loadingReset
    },
    on: {
      click: _vm.handleResetPwd
    }
  }, [_vm._v("Reset mật khẩu")]), _vm.form.refStaff ? _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "danger",
      type: "warning",
      loading: _vm.loadingRemoveAgent
    },
    on: {
      click: _vm.handleRemoveAgent
    }
  }, [_vm._v("Gỡ đại lý")]) : _vm._e()], 1) : _vm._e(), _c("div", {}, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)]) : _vm._e(), _c("agency-modal", {
    ref: "AgencyModal",
    attrs: {
      staffId: _vm.form.id
    },
    on: {
      "submit:success": _vm.handleCloseModal
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;