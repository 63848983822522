"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật chương" : "Thêm mới chương",
      "append-to-body": "",
      visible: _vm.visible,
      width: "700px",
      "close-on-click-modal": false,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForm,
      expression: "loadingForm"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin",
      name: "info"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Icon",
      prop: "icon"
    }
  }, [_c("SingleImage", {
    attrs: {
      width: "100",
      height: "100",
      pathImage: _vm.form.icon,
      urlUpload: "/v1/admin/book/upload"
    },
    on: {
      "upload:success": _vm.setIcon
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề (Tiếng Việt)",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề (Tiếng Anh)",
      prop: "nameEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameEn", $$v);
      },
      expression: "form.nameEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề (Tiếng Nhật)",
      prop: "nameJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameJp", $$v);
      },
      expression: "form.nameJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Pdf file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.pdfChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfChooseType", $$v);
      },
      expression: "form.pdfChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.pdfChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfChooseType", $$v);
      },
      expression: "form.pdfChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.pdfChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      action: "".concat(_vm.$baseUrl, "/v1/admin/book/upload"),
      limit: 1,
      "on-success": _vm.handleUploadPdfOk,
      "before-upload": _vm.beforeUploadPdf,
      "on-remove": _vm.handleRemovePdf,
      headers: {
        token: _vm.token
      },
      "file-list": _vm.pdfFileList
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _c("el-input", {
    model: {
      value: _vm.form.pdfLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pdfLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.pdfLink"
    }
  })] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Audio file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.audioChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioChooseType", $$v);
      },
      expression: "form.audioChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.audioChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioChooseType", $$v);
      },
      expression: "form.audioChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.audioChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": _vm.handleUploadAudioOk,
      "file-list": _vm.audioFileList,
      "on-remove": _vm.handleRemoveAudio
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _c("el-input", {
    model: {
      value: _vm.form.audioLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "audioLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.audioLink"
    }
  })] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Video file"
    }
  }, [_vm.visibleMediaLink ? [_c("el-radio", {
    attrs: {
      label: _vm.ChooseType.File
    },
    model: {
      value: _vm.form.videoChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoChooseType", $$v);
      },
      expression: "form.videoChooseType"
    }
  }, [_vm._v("File")]), _c("el-radio", {
    attrs: {
      label: _vm.ChooseType.Link
    },
    model: {
      value: _vm.form.videoChooseType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoChooseType", $$v);
      },
      expression: "form.videoChooseType"
    }
  }, [_vm._v("Link")]), _vm.form.videoChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "vi");
      },
      "file-list": _vm.videoFileList,
      "on-remove": _vm.handleRemoveVideo
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Việt")])], 1) : _vm._e(), _vm.form.videoChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "en");
      },
      "file-list": _vm.videoFileListEn,
      "on-remove": _vm.handleRemoveVideoEn
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Anh")])], 1) : _vm._e(), _vm.form.videoChooseType == _vm.ChooseType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "jp");
      },
      "file-list": _vm.videoFileListJp,
      "on-remove": _vm.handleRemoveVideoJp
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Nhật")])], 1) : _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      gap: "8px"
    }
  }, [_c("div", [_c("label", [_vm._v("Link Tiếng Việt")]), _c("el-input", {
    model: {
      value: _vm.form.videoLink,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoLink", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.videoLink"
    }
  })], 1), _c("div", [_c("label", [_vm._v("Link Tiếng Anh")]), _c("el-input", {
    model: {
      value: _vm.form.videoLinkEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoLinkEn", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.videoLinkEn"
    }
  })], 1), _c("div", [_c("label", [_vm._v("Link Tiếng Nhật")]), _c("el-input", {
    model: {
      value: _vm.form.videoLinkJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "videoLinkJp", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.videoLinkJp"
    }
  })], 1)])] : _c("span", [_vm._v(" Không có quyền ")])], 2), _c("el-form-item", {
    attrs: {
      label: "Thể thao"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.isYogaVideo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isYogaVideo", $$v);
      },
      expression: "form.isYogaVideo"
    }
  }, [_vm._v("Là video thể thao?")]), _vm.form.isYogaVideo ? [_c("br"), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái keypoint: ")]), _vm.keypointStatus == "NONE" ? _c("span", [_vm._v(" Chưa có video ")]) : _vm.keypointStatus == "OK" ? _c("span", [_vm._v(" Đã có keypoint ")]) : _c("span", [_vm._v(" Chưa có keypoint ")]), _c("br"), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.handleProcessKeypoint
    }
  }, [_vm._v(" Lưu trữ ")])] : _vm._e()], 2), _c("el-form-item", {
    attrs: {
      label: "Khảo sát"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.BookChapterSurveyDataType.File
    },
    model: {
      value: _vm.form.surveyDataType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyDataType", $$v);
      },
      expression: "form.surveyDataType"
    }
  }, [_vm._v(" File ")]), _c("el-radio", {
    attrs: {
      label: _vm.BookChapterSurveyDataType.Url
    },
    model: {
      value: _vm.form.surveyDataType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyDataType", $$v);
      },
      expression: "form.surveyDataType"
    }
  }, [_vm._v(" Dạng URL ")]), _c("el-radio", {
    attrs: {
      label: _vm.BookChapterSurveyDataType.Html
    },
    model: {
      value: _vm.form.surveyDataType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyDataType", $$v);
      },
      expression: "form.surveyDataType"
    }
  }, [_vm._v(" Dạng HTML ")]), _vm.form.surveyDataType == _vm.BookChapterSurveyDataType.File ? _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$baseUrl, "/v1/admin/book/upload/scorm"),
      limit: 1,
      "on-success": _vm.handleUploadScormOk,
      "file-list": _vm.scormFileList,
      "before-upload": _vm.beforeUploadScorm,
      "on-remove": _vm.handleRemoveScorm
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Click để upload")])], 1) : _vm._e(), _vm.form.surveyDataType == _vm.BookChapterSurveyDataType.Url ? _c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.surveyContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyContent", $$v);
      },
      expression: "form.surveyContent"
    }
  }) : _vm._e(), _vm.form.surveyDataType == _vm.BookChapterSurveyDataType.Html && _vm.visible ? _c("Tinymce", {
    ref: "editor",
    on: {
      onInit: _vm.handleEditorInit
    },
    model: {
      value: _vm.form.surveyContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surveyContent", $$v);
      },
      expression: "form.surveyContent"
    }
  }) : _vm._e()], 1), _c("el-form-item", {
    attrs: {
      label: "Trắc nghiệm"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: "Please enter a keyword",
      "remote-method": _vm.filterQuiz
    },
    model: {
      value: _vm.quizInput,
      callback: function callback($$v) {
        _vm.quizInput = $$v;
      },
      expression: "quizInput"
    }
  }, _vm._l(_vm.quizFilter, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.name
      }
    });
  }), 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Hướng dẫn",
      name: "manual"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh hướng dẫn",
      prop: "image"
    }
  }, [_c("SingleImage", {
    attrs: {
      width: "100",
      height: "100",
      pathImage: _vm.form.image,
      urlUpload: "/v1/admin/book/upload"
    },
    on: {
      "upload:success": _vm.setImage
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung hướng dẫn (Tiếng Việt)",
      prop: "tutorial"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.tutorial,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tutorial", $$v);
      },
      expression: "form.tutorial"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung hướng dẫn (Tiếng Anh)",
      prop: "tutorialEn"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.tutorialEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tutorialEn", $$v);
      },
      expression: "form.tutorialEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung hướng dẫn (Tiếng Nhật)",
      prop: "tutorialJp"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.tutorialJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tutorialJp", $$v);
      },
      expression: "form.tutorialJp"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;