"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookType = exports.BookSyncFromTrans = exports.BookSyncFrom = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BookType;
(function (BookType) {
  BookType["Physical"] = "PHYSICAL";
  BookType["EBook"] = "E-BOOK";
})(BookType || (exports.BookType = BookType = {}));
var BookSyncFrom;
(function (BookSyncFrom) {
  BookSyncFrom["Visedu"] = "VISEDU";
  BookSyncFrom["Batch"] = "BATCH";
  BookSyncFrom["Opac"] = "OPAC";
})(BookSyncFrom || (exports.BookSyncFrom = BookSyncFrom = {}));
var BookSyncFromTrans = exports.BookSyncFromTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookSyncFrom.Visedu, "Từ thư viện số Dspace"), BookSyncFrom.Batch, "Nhập theo lô"), BookSyncFrom.Opac, "Từ Sierra (Sách giấy)");