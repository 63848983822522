"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên gói tập"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm gói tập")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên gói tập",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượt chat trong ngày",
      prop: "limitChatBot",
      width: "180",
      align: "right"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Chi tiết gói tập"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return _vm._l(row.packDetails, function (item) {
          return row.packDetails ? _c("div", [_c("div", [_c("div", [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Tên lựa chọn (Tiếng Việt): ")]), _c("span", [_vm._v(_vm._s(item.name))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Giá gói (VNĐ): ")]), _c("span", [_vm._v(_vm._s(item.price))])])]), _c("div", [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Tên lựa chọn (Tiếng Anh): ")]), _c("span", [_vm._v(_vm._s(item.nameEn))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Giá gói (USD): ")]), _c("span", [_vm._v(_vm._s(item.priceEn))])])]), _c("div", [_c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Tên lựa chọn (Tiếng Nhật): ")]), _c("span", [_vm._v(_vm._s(item.nameJp))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Giá gói (JPY): ")]), _c("span", [_vm._v(_vm._s(item.priceJp))])])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Số ngày hết hạn: ")]), _c("span", [_vm._v(_vm._s(item.expiresDay))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Giảm giá (%): ")]), _c("span", [_vm._v(_vm._s(item.discountPercent))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Số ngày miễn phí: ")]), _c("span", [_vm._v(_vm._s(item.freeDays))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Apple Id: ")]), _c("span", [_vm._v(_vm._s(item.appleId))])]), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Google Id: ")]), _c("span", [_vm._v(_vm._s(item.googleId))])])]), row.packDetails.length > 1 ? _c("el-divider") : _vm._e()], 1) : _vm._e();
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Bài tập"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.books ? _c("span", [_vm._v(_vm._s(row.books.map(function (e) {
          return e.name;
        }).join(", ")))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column"
          }
        }, [_c("el-button", {
          staticStyle: {
            width: "102px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")]), _c("el-button", {
          staticStyle: {
            "margin-left": "0",
            "margin-top": "4px",
            width: "102px"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")]), _c("el-button", {
          staticStyle: {
            "margin-left": "0",
            "margin-top": "4px",
            width: "102px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-refresh",
            loading: _vm.loadingSync
          },
          on: {
            click: function click($event) {
              return _vm.handleSync(row);
            }
          }
        }, [_vm._v("Sync")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("RecommendBookStrategyModal", {
    ref: "RecommendBookStrategyModal",
    attrs: {
      isShowApprove: true
    },
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;