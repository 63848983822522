"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đại lý")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: ""
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "staffId", $$v);
      },
      expression: "query.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tháng")]), _c("el-date-picker", {
    attrs: {
      type: "month",
      size: "medium",
      placeholder: "Chọn tháng",
      clearable: "",
      format: "MM-yyyy"
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.month,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "month", $$v);
      },
      expression: "query.month"
    }
  })], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.recordSales,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tháng",
      width: "100px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(_vm._s(row.month) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ghi nhận cho đại lý"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.staff.avatar ? _vm.$url.image(row.staff.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.staff.name))]), _c("div", [_c("div", [_c("strong", [_vm._v("Username:")]), _vm._v(_vm._s(row.staff.username))])])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng doanh số",
      align: "right",
      width: "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row.totalSaleAmount)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng số người được giới thiệu",
      align: "right",
      width: "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(_vm._s(row.totalRef) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng số nhà phân phối",
      align: "right",
      width: "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(_vm._s(row.totalRefNPPP) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chi tiết",
      width: "150px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewDetails(row);
            }
          }
        }, [_vm._v(" Xem chi tiết ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thời gian",
      width: "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchRecordSale
    }
  }), _c("el-dialog", {
    attrs: {
      title: "Chi tiết",
      visible: _vm.showModal,
      width: "900px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showModal = $event;
      },
      close: _vm.resetDetails
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.recordSaleDetails,
      border: "",
      fit: "",
      lazy: true
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "percent",
      label: "Mã đơn hàng",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var _row$order;
        var row = _ref8.row;
        return [_vm._v(_vm._s(row === null || row === void 0 || (_row$order = row.order) === null || _row$order === void 0 ? void 0 : _row$order.code))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "percent",
      label: "Tổng đơn hàng",
      align: "right",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var _row$order2;
        var row = _ref9.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row === null || row === void 0 || (_row$order2 = row.order) === null || _row$order2 === void 0 ? void 0 : _row$order2.amount)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "percent",
      label: "Phần trăm CK",
      align: "right",
      width: "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.percent) + "%")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "Số tiền CK",
      align: "right",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_vm._v(_vm._s(_vm.$formatNumberVN(row === null || row === void 0 ? void 0 : row.amount)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "Loại CK"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v(_vm._s(_vm.CommissionConfigTypeTrans[row === null || row === void 0 ? void 0 : row.type]))];
      }
    }])
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.showModal = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;