"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm$detailNpp, _vm$detailNpp2, _vm$detailNpp$npp;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_vm.user.role.type == _vm.SyncPortalRole.QuanTriVien ? _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại đại lý")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: ""
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, _vm._l(_vm.staffTypes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tháng")]), _c("el-date-picker", {
    attrs: {
      type: "month",
      size: "medium",
      placeholder: "Chọn tháng",
      clearable: "",
      format: "MM-yyyy"
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.month,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "month", $$v);
      },
      expression: "query.month"
    }
  })], 1)]), _vm.user.role.type !== _vm.SyncPortalRole.DaiLy2 && _vm.user.role.type !== _vm.SyncPortalRole.DaiLy ? _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.listNPPPFinal,
      fit: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.avatar ? _vm.$url.image(row.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.name))])])];
      }
    }], null, false, 3416701186)
  }), _c("el-table-column", {
    attrs: {
      label: "Username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row.username))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SyncId: ")]), _c("span", [_vm._v(_vm._s(row.syncId))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mật khẩu khởi tạo: ")]), _c("span", [_vm._v(_vm._s(row.initPassword))])];
      }
    }], null, false, 3685683677)
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại",
      prop: "phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin thêm",
      prop: "other"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _row$role;
        var row = _ref3.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), row.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), row.dob ? _c("span", [_vm._v(_vm._s(row.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), row.email ? _c("span", [_vm._v(_vm._s(row.email))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), row.zalo ? _c("span", [_vm._v(_vm._s(row.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), row.facebook ? _c("span", [_vm._v(_vm._s(row.facebook))]) : _c("span", [_vm._v("--")]), _c("br"), ((_row$role = row.role) === null || _row$role === void 0 ? void 0 : _row$role.type) === _vm.SyncPortalRole.DaiLy ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("% hoa hồng: ")]), row.commissionPercent ? _c("span", [_vm._v(_vm._s(row.commissionPercent))]) : _c("span", [_vm._v("--")])]) : _vm._e()];
      }
    }], null, false, 445181182)
  }), _c("el-table-column", {
    attrs: {
      label: "Loại đại lý"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.role ? row.role.name : "-") + " ")];
      }
    }], null, false, 1654337850)
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng số KH giới thiệu",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalRefCustomer)) + " ")];
      }
    }], null, false, 1136325941)
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng doanh thu cá nhân",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.totalSaleNPPPAmount)) + " ")];
      }
    }], null, false, 3290000745)
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng doanh thu NPPP",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.role.type == _vm.SyncPortalRole.DaiLy ? _vm.$formatNumberVN(row.totalSale) : "--") + " ")];
      }
    }], null, false, 543803944)
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái kích hoạt",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [!row.isBlocked ? [_c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.expiredAt)))]) : _vm._e()] : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Chưa kích hoạt ")])];
      }
    }], null, false, 2030966034)
  }), _c("el-table-column", {
    attrs: {
      label: "Chi tiết",
      width: "150px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewDetails(row);
            }
          }
        }, [_vm._v(" Xem chi tiết ")])];
      }
    }], null, false, 3707345139)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchNpp
    }
  })], 1) : _c("div", [_vm.npp ? _c("div", [_vm.user.role.type == _vm.SyncPortalRole.DaiLy ? _c("p", [_c("strong", [_vm._v("Tổng số NPPP:")]), _vm._v(" " + _vm._s(_vm.npp.totalNPPP) + " ")]) : _vm._e(), _c("p", [_c("strong", [_vm._v("Tổng số KH đã giới thiệu:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.npp.totalCustomer)) + " ")]), _c("p", [_c("strong", [_vm._v("Tổng doanh thu cá nhân:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.npp.totalSaleByMe)) + " ")]), _vm.user.role.type == _vm.SyncPortalRole.DaiLy ? _c("p", [_c("strong", [_vm._v("Tổng doanh thu NPPP:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.npp.totalSaleNPPP)) + " ")]) : _vm._e()]) : _vm._e(), _c("el-divider"), _vm.npp.refs && _vm.npp.refs.length > 0 ? _c("div", [_c("h4", [_vm._v("Danh sách NPPP")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingDetail,
      expression: "loadingDetail"
    }],
    attrs: {
      data: _vm.npp.refs,
      fit: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.staff.avatar ? _vm.$url.image(row.staff.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.staff.name))])])];
      }
    }], null, false, 1551186378)
  }), _c("el-table-column", {
    attrs: {
      label: "Username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row.staff.username))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SyncId: ")]), _c("span", [_vm._v(_vm._s(row.staff.syncId))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mật khẩu khởi tạo: ")]), _c("span", [_vm._v(_vm._s(row.staff.initPassword))])];
      }
    }], null, false, 1104915093)
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại",
      prop: "staff.phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin thêm",
      prop: "other"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var _row$staff$role;
        var row = _ref12.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), row.staff.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.staff.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), row.staff.dob ? _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.staff.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), row.staff.email ? _c("span", [_vm._v(_vm._s(row.staff.email))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), row.staff.zalo ? _c("span", [_vm._v(_vm._s(row.staff.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), row.staff.facebook ? _c("span", [_vm._v(_vm._s(row.staff.facebook))]) : _c("span", [_vm._v("--")]), _c("br"), ((_row$staff$role = row.staff.role) === null || _row$staff$role === void 0 ? void 0 : _row$staff$role.type) === _vm.SyncPortalRole.DaiLy ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("% hoa hồng: ")]), row.staff.commissionPercent ? _c("span", [_vm._v(_vm._s(row.staff.commissionPercent))]) : _c("span", [_vm._v("--")])]) : _vm._e()];
      }
    }], null, false, 3644307881)
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái kích hoạt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [!row.staff.isBlocked ? [_c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.staff.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.staff.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.staff.expiredAt)))]) : _vm._e()] : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Chưa kích hoạt ")])];
      }
    }], null, false, 840852370)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchNpp
    }
  })], 1) : _vm._e()], 1), _c("el-dialog", {
    attrs: {
      title: "Chi tiết",
      visible: _vm.showModal,
      width: "900px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showModal = $event;
      },
      close: _vm.resetDetails
    }
  }, [_vm.detailNpp ? _c("div", [_c("p", [_c("strong", [_vm._v("Username:")]), _vm._v(" " + _vm._s((_vm$detailNpp = _vm.detailNpp) === null || _vm$detailNpp === void 0 || (_vm$detailNpp = _vm$detailNpp.npp) === null || _vm$detailNpp === void 0 ? void 0 : _vm$detailNpp.username) + " ")]), _c("p", [_c("strong", [_vm._v("Tên đại lý:")]), _vm._v(" " + _vm._s((_vm$detailNpp2 = _vm.detailNpp) === null || _vm$detailNpp2 === void 0 || (_vm$detailNpp2 = _vm$detailNpp2.npp) === null || _vm$detailNpp2 === void 0 ? void 0 : _vm$detailNpp2.name) + " ")]), _c("p", [_c("strong", [_vm._v("Tổng số KH giới thiệu:")]), _vm._v(" " + _vm._s(_vm.detailNpp.totalRefCustomer) + " ")]), _c("p", [_c("strong", [_vm._v("Tổng doanh thu cá nhân:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.detailNpp.totalSaleByMe)) + " ")]), ((_vm$detailNpp$npp = _vm.detailNpp.npp) === null || _vm$detailNpp$npp === void 0 ? void 0 : _vm$detailNpp$npp.role.type) == "DAI_LY" ? _c("p", [_c("strong", [_vm._v("Tổng doanh thu NPPP:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.detailNpp.totalSaleNPPP)) + " ")]) : _vm._e()]) : _vm._e(), _c("el-divider"), _vm.detailNpp.refs && _vm.detailNpp.refs.length > 0 ? _c("div", [_c("h4", [_vm._v("Danh sách NPPP")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingDetail,
      expression: "loadingDetail"
    }],
    attrs: {
      data: _vm.detailNpp.refs,
      fit: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.staff.avatar ? _vm.$url.image(row.staff.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.staff.name))])])];
      }
    }], null, false, 1551186378)
  }), _c("el-table-column", {
    attrs: {
      label: "Username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row.staff.username))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SyncId: ")]), _c("span", [_vm._v(_vm._s(row.staff.syncId))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mật khẩu khởi tạo: ")]), _c("span", [_vm._v(_vm._s(row.staff.initPassword))])];
      }
    }], null, false, 1104915093)
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại",
      prop: "staff.phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin thêm",
      prop: "other"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var _row$staff$role2;
        var row = _ref16.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), row.staff.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.staff.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), row.staff.dob ? _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.staff.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), row.staff.email ? _c("span", [_vm._v(_vm._s(row.staff.email))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), row.staff.zalo ? _c("span", [_vm._v(_vm._s(row.staff.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), row.staff.facebook ? _c("span", [_vm._v(_vm._s(row.staff.facebook))]) : _c("span", [_vm._v("--")]), _c("br"), ((_row$staff$role2 = row.staff.role) === null || _row$staff$role2 === void 0 ? void 0 : _row$staff$role2.type) === _vm.SyncPortalRole.DaiLy ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("% hoa hồng: ")]), row.staff.commissionPercent ? _c("span", [_vm._v(_vm._s(row.staff.commissionPercent))]) : _c("span", [_vm._v("--")])]) : _vm._e()];
      }
    }], null, false, 3644307881)
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái kích hoạt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var row = _ref17.row;
        return [!row.staff.isBlocked ? [_c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.staff.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.staff.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.staff.expiredAt)))]) : _vm._e()] : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Chưa kích hoạt ")])];
      }
    }], null, false, 840852370)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchNpp
    }
  })], 1) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.showModal = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;