"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FunctionTypeTrans = exports.FunctionType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _FunctionTypeTrans;
var FunctionType;
(function (FunctionType) {
  FunctionType["HealthSurvey"] = "HEALTH_SURVEY";
  FunctionType["AIPractice"] = "AI_PRACTICE";
  FunctionType["NormalPractice"] = "NORMAL_PRACTICE";
  FunctionType["ReceiveNotification"] = "RECEIVE_NOTIFICATION";
  FunctionType["RoadmapCapture"] = "ROADMAP_CAPTURE";
  FunctionType["SocialShare"] = "SOCIAL_SHARE";
  FunctionType["BuyPackage"] = "BUY_PACKAGE";
  FunctionType["Intro1"] = "INTRO1";
  FunctionType["Intro2"] = "INTRO2";
  FunctionType["Intro3"] = "INTRO3";
  FunctionType["NormalWorkout1"] = "NORMAL_WORKOUT1";
  FunctionType["NormalWorkout2"] = "NORMAL_WORKOUT2";
  FunctionType["NormalWorkout3"] = "NORMAL_WORKOUT3";
  FunctionType["NormalWorkout4"] = "NORMAL_WORKOUT4";
  FunctionType["AIWorkout1"] = "AI_WORKOUT1";
  FunctionType["AIWorkout2"] = "AI_WORKOUT2";
  FunctionType["AIWorkout3"] = "AI_WORKOUT3";
  FunctionType["AIWorkout4"] = "AI_WORKOUT4";
})(FunctionType || (exports.FunctionType = FunctionType = {}));
var FunctionTypeTrans = exports.FunctionTypeTrans = (_FunctionTypeTrans = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_FunctionTypeTrans, FunctionType.HealthSurvey, "Làm khảo sát sức khỏe"), FunctionType.AIPractice, "Tập luyện với trợ lý ảo AI"), FunctionType.NormalPractice, "Tập luyện thường"), FunctionType.ReceiveNotification, "Nhận thông báo nhắc nhở tập luyện"), FunctionType.RoadmapCapture, "Chụp ảnh người dùng theo lộ trình"), FunctionType.SocialShare, "Chia sẻ mạng xã hội"), FunctionType.BuyPackage, "Hướng dẫn mua gói tập"), FunctionType.Intro1, "Hướng dẫn"), FunctionType.Intro2, "Giới thiệu"), FunctionType.Intro3, "Video hướng dẫn sau khi login"), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_FunctionTypeTrans, FunctionType.NormalWorkout1, "Tập thường tiến độ 0 - 10%"), FunctionType.NormalWorkout2, "Tập thường tiến độ 10 - 50%"), FunctionType.NormalWorkout3, "Tập thường tiến độ 50 - 70%"), FunctionType.NormalWorkout4, "Tập thường tiến độ 70 - 100%"), FunctionType.AIWorkout1, "Tập AI tiến độ 0 - 10%"), FunctionType.AIWorkout2, "Tập AI tiến độ 10 - 50%"), FunctionType.AIWorkout3, "Tập AI tiến độ 50 - 70%"), FunctionType.AIWorkout4, "Tập AI tiến độ 70 - 100%"));