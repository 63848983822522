"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.commissionConfigs,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loại",
      prop: "type",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(_vm.CommissionConfigTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "% hoa hồng",
      prop: "percent",
      width: "200",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s(row.percent))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Doanh số tối thiểu",
      prop: "minAmount",
      width: "200",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(_vm._s(_vm.formatCurrency(row.minAmount)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Doanh số tối đa",
      prop: "maxAmount",
      width: "200",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(_vm.formatCurrency(row.maxAmount)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng giới thiệu tối thiểu",
      prop: "ref",
      width: "200",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(row.ref))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số lượng NPP",
      prop: "ref",
      width: "200",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("span", [_vm._v(_vm._s(row.refNPPP))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Doanh số của NPP chính và NPP phụ",
      prop: "ref",
      width: "250",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", [_vm._v(_vm._s(_vm.formatCurrency(row.totalNPPPMinAmount)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchCommissionConfig
    }
  }), _c("CommissionConfigModal", {
    ref: "CommissionConfigModal",
    on: {
      submitOk: _vm.fetchCommissionConfig
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;